import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import SwiperCore from "swiper";
import classNames from "classnames";
import BannersSection from "./BannersSection/BannersSection";
import MainPageLoader from "../../presentational/Loaders/MainPageLoader/MainPageLoader";
import MetaDecorator from "../../presentational/MetaDecorator/MetaDecorator";
import SendIdeaForm from "../../containers/Forms/SendIdeaForm/SendIdeaForm";
import FadeIn from "../../presentational/FadeIn/FadeIn";
import { getNewsLines } from "../../../store/actions/NewsLineActions";

import {
  lessenMainPage,
  setAppColor,
} from "../../../store/actions/LayoutActions";
import { getProjectBanners } from "../../../store/actions/ProjectActions";
import { getTotalStatistics } from "../../../store/actions/StatisticsActions";
import { getCategories } from "../../../store/actions/ProposeActions";
import { findUrlParam, clearUrlParams } from "../../../utils/index";
import { RootState } from "../../../types/State.interface";

import { AppColorsEnum } from "../../App.interface";
import EventsTrackParentIdContext from "../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperScroll from "../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import BannerLine from "./BannerLine/BannerLine";

import ExternalLinks from "./ExternalLinks/ExternalLinks";
import useRedirectUrl from "../../../hooks/useRedirectUrl";
import AgVideoWidget from "../../presentational/AgVideoWidget/AgVideoWidget";
import { selectEnvironment } from "../../../store/selectors/environment";
import { selectIsUserReady } from "../../../store/selectors/profile";
import styles from "./MainPage.module.scss";
import Heading from "../../presentational/Typography/Heading";
import { keys } from "../../../utils/object";
import IdeasSection from "./IdeasSection/IdeasSection";
import ResultSection from "./ResultSection/ResultSection";
import NewsSection from "./NewsSection/NewsSection";
import AboutSection from "./AboutSection/AboutSection";
import ProposeIdeaSection from "./ProposeIdeaSection/ProposeIdeaSection";
import { selectCurrentProjects } from "../../../store/selectors/project";
import { selectBanners } from "../../../store/selectors/banner";
import { selectSettings } from "../../../store/selectors/sittings";
import { PromoBlocks } from "../../../types/Common.interface";
import BannerDirections from "./BannerDirections/BannerDirections";

declare global {
  interface Window {
    ga: any;
  }
}

const MainPage = () => {
  useRedirectUrl();

  const dispatch = useDispatch();

  // SELCTORS
  const user = useSelector((state: RootState) => state.user.userDetails);
  const isUserReady = useSelector(selectIsUserReady);
  const banners = useSelector(selectBanners);
  const categories = useSelector(
    (state: RootState) => state.propose.categories
  );
  const currentProjects = useSelector(selectCurrentProjects);
  const totalStatistics = useSelector(
    (state: RootState) => state.totalStatistics.stats
  );
  const { isProduction } = useSelector(selectEnvironment);
  const { PROMO_BLOCKS: promoBlocks } = useSelector(selectSettings);
  const linesState = useSelector((state: RootState) => state.lines);

  // STATE
  const [ideaUploadModalOpen, setIdeaUploadModalOpen] = useState(false);
  const [queriedForm, setQueriedForm] = useState<
    "proposeForm" | "ideaUpload"
  >();
  const [swiperLines, setSwiperLines] = useState<SwiperCore>();
  const [trio, setTrio] = useState<any>();

  const sections = useMemo(() => {
    const blocks = {
      [PromoBlocks.ABOUT]: {
        Component: AboutSection,
        title: "О платформе",
      },
      [PromoBlocks.PROPOSE]: {
        Component: ProposeIdeaSection,
        title: "Предлагайте идеи по развитию города",
      },
      [PromoBlocks.NEWS]: {
        Component: NewsSection,
        title: "Новости",
      },
      [PromoBlocks.RESULTS]: {
        Component: ResultSection,
        title: "Результаты работы",
      },
      [PromoBlocks.IDEAS]: {
        Component: IdeasSection,
        title: "Подтверждайте реализацию идей",
      },
    };

    const res = [];
    promoBlocks.forEach((promoBlock) => {
      if (blocks[promoBlock]) {
        res.push({ key: promoBlock, ...blocks[promoBlock] });
        delete blocks[promoBlock];
      }
    });
    keys(blocks).forEach((block) => {
      res.push({ key: block, ...blocks[block] });
    });

    return res;
  }, [currentProjects?.length, promoBlocks]);

  useEffect(() => {
    dispatch(lessenMainPage());
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(getNewsLines());
    loadData();
  }, []);

  // одновременный запуск перетяжки и трио слайдера для синхронного переключения
  useEffect(() => {
    if (swiperLines && trio) {
      const t = setTimeout(() => {
        if (linesState.lines?.length > 1) {
          swiperLines.autoplay.stop();
          swiperLines.autoplay.start();
        }

        trio.autoPlay();
      }, 100);

      return () => clearTimeout(t);
    }
  }, [linesState.lines?.length, swiperLines, trio]);

  // METHODS
  const loadData = () => {
    if (!banners) dispatch(getProjectBanners());
    if (!categories) dispatch(getCategories());
    if (!totalStatistics) dispatch(getTotalStatistics());
  };

  const canRender = () =>
    banners && currentProjects && totalStatistics && categories;
  // && linesState.lines;

  useEffect(() => {
    setQueriedForm(
      findUrlParam("proposeForm", window.location.search)
        ? "proposeForm"
        : findUrlParam("ideaUpload", window.location.search)
          ? "ideaUpload"
          : undefined
    );
  }, []);

  useEffect(() => {
    // открытие форм после авторизации
    if (isUserReady && queriedForm && categories?.length) {
      switch (queriedForm) {
        case "proposeForm":
          const categoryCode = findUrlParam("category", window.location.search);
          let category;

          if (categoryCode) {
            category = categories?.find((el) => el.code === categoryCode);
          }

          // !proposeModalOpen && openProposeIdeaForm(null, category);
          break;
        case "ideaUpload":
          !ideaUploadModalOpen && setIdeaUploadModalOpen(true);
          break;
      }

      queriedForm && clearUrlParams();
    } else {
      setIdeaUploadModalOpen(false);
      // setProposeModalOpen(false);
    }
  }, [isUserReady, categories]);

  // RENDER
  const renderMeta = () => {
    const title = "Платформа Правительства Москвы «Город идей»";
    const description =
      "Платформа Правительства Москвы «Город идей» создана в 2014 году и является многофункциональной и современной платформой по взаимодействию органов исполнительной власти и жителей, сбору предложений по ключевым для столицы вопросам и подтверждению реализованных городом идей.";

    return (
      <MetaDecorator
        title={title}
        description={description}
        opengraph={{ title, description }}
      />
    );
  };

  const renderExternalProjects = () => (
    <EventsTrackParentIdContext.Provider value="BLOCK_GKU_NTU">
      <EventsTrackWrapperScroll id={7}>
        <div>
          <ExternalLinks />
        </div>
      </EventsTrackWrapperScroll>
    </EventsTrackParentIdContext.Provider>
  );

  const renderContent = () => (
    <FadeIn>
      <AgVideoWidget
        className={styles["ag-video"]}
        partnerId={isProduction ? 9 : 8}
      />
      <div
        className={classNames(styles.main, {
          [styles["new-line-top"]]: !!linesState.lines?.length,
        })}
      >
        {renderMeta()}
        <BannerLine
          lines={linesState.lines || []}
          onInitSwiper={setSwiperLines}
        />
        <EventsTrackParentIdContext.Provider value="PAGE_MAIN">
          <div className={styles.sections}>
            <BannersSection onInitTrio={setTrio} />

            {!!currentProjects?.length && (
              <div className={styles.section}>
                <Heading className={styles["section-title"]} size={2}>
                  На проекте вы можете прямо сейчас
                </Heading>
                <BannerDirections />
              </div>
            )}

            {sections.map(({ key, title, Component }) => (
              <div key={key} className={styles.section}>
                <Heading className={styles["section-title"]} size={2}>
                  {title}
                </Heading>
                <Component />
              </div>
            ))}

            {renderExternalProjects()}
          </div>
        </EventsTrackParentIdContext.Provider>

        {ideaUploadModalOpen && (
          <SendIdeaForm
            isOpened={ideaUploadModalOpen}
            user={user}
            onClose={() => setIdeaUploadModalOpen(false)}
          />
        )}
      </div>
    </FadeIn>
  );

  const renderLoader = () => (
    <>
      {renderMeta()}

      <MainPageLoader />
    </>
  );

  return <>{canRender() ? renderContent() : renderLoader()}</>;
};

export default MainPage;
